/* .singupchart canvas {
    height: 550px !important;
    width: 1181.6px;
} */
.singupchart1 canvas {
    display: block;
    box-sizing: border-box;
    height: 450.8px;
    width: 450.6px;
}
.singupchart canvas {
    height: 500px !important;
    width: 100% !important;
}
.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 80%;
}